/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
  function isiPhone() {
    return (
      navigator.platform.indexOf("iPhone") != -1 ||
      navigator.platform.indexOf("iPod") != -1
    );
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        $(".slick").slick({
          dots: true,
          autoplay: true,
          autoplaySpeed: 3500,
          infinite: true,
        });

        $("#menu").on("click", function (e) {
          e.preventDefault();
          $(".nav-burger").toggleClass("open");
          $(".navigation").toggleClass("show");
          $(".navigation").removeClass("maxWidth");
          $(".heading").removeClass("remove");
        });

        $("nav li a").on("click", function () {
          $(".nav-burger").removeClass("open");
          $(".navigation").removeClass("show maxWidth");
          $(".heading").removeClass("remove");
          const title = $(this).attr("title");
          title === "Rahmen" ? $("#rahmen_modal").modal("show") : null;
          title === "Projekte" ? $("#projekte_modal").modal("show") : null;
          title === "Dienstleistungen"
            ? $("#dienstleistungen_modal").modal("show")
            : null;

          title === "Frames" ? $("#frame_modal").modal("show") : null;
          title === "Projects" ? $("#project_modal").modal("show") : null;
          title === "Services" ? $("#services_modal").modal("show") : null;
        });

        $("nav li").mouseenter(function () {
          var title = $(this).attr("title");
          $(this)
            .closest(".navigation")
            .addClass("maxWidth " + title);
          $(this).closest("header").find(".heading").addClass("remove");
        });

        $("nav li").mouseleave(function () {
          var title = $(this).attr("title");
          $(this).closest(".navigation").removeClass(title);
        });

        $(".modal").on("show.bs.modal", function (e) {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            // some code..
            $("header").hide();
          }
          if ($(window).width() <= 768) {
            $("header").css("z-index", 2);
          }
        });

        $(".modal").on("hidden.bs.modal", function (e) {
          if (isiPhone()) {
            $("header").css("z-index", 20);
          }
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            // some code..
            $("header").show();
          }
          if ($(window).width() <= 768) {
            $("header").css("z-index", 20);
          }
        });

        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // define an observer instance
        var observer = new IntersectionObserver(onIntersection, {
          root: null, // default is the viewport
          threshold: 0.2, // percentage of taregt's visible area. Triggers "onIntersection"
        });
        // callback is called on intersection change
        function onIntersection(entries, opts) {
          entries.forEach((entry) => {
            entry.target.classList.toggle("visible", entry.isIntersecting);
            if (
              entry.isIntersecting === true &&
              entry.target.classList.contains("bg-header")
            ) {
              const Header = document.querySelector("header");
              Header.classList.toggle("bg-grey");
            } else {
              const Header = document.querySelector("header");
              Header.classList.remove("bg-grey");
            }
          });
        }

        // Use the observer to observe an element
        if ($("html").is(":lang(de-de)")) {
          observer.observe(document.querySelector(".rahmen"));
          observer.observe(document.querySelector(".projekte"));
          observer.observe(document.querySelector(".dienstleistungen"));
          observer.observe(document.querySelector("#uberuns-content"));
          observer.observe(document.querySelector("#kontakt-content"));
          observer.observe(document.querySelector("#newsletter"));
          observer.observe(document.querySelector("#impressum"));
        } else {
          observer.observe(document.querySelector(".frame"));
          observer.observe(document.querySelector(".project"));
          observer.observe(document.querySelector(".services"));
          observer.observe(document.querySelector("#contact-content"));
        }
      

        // JavaScript to be fired on the home page
      },
      finalize: function () {
        $(".lightbox a").simpleLightbox({
          overlay: true,
          nav: false,
          showCounter: false,
          doubleTapZoom: 0,
          scrollZoom: false,
          captionPosition: "outside",
        });

        // JavaScript to be fired on the home page, after the init JS
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
